import React from "react";
import dynamic from "next/dynamic";
const OutscalAnimatedLogo = dynamic(() =>
  import("@/Components/OutscalAnimatedLogo")
);
import { useRouter } from "next/router";
import { styled } from "styled-components";
import Constant from "@/staticData/constant";
import useAuthActions from "@/store/actions/authAction";
import { RowDiv, YellowButton } from "@/outscal-commons-frontend/Styled";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import { FREE_TRIAL_COURSE_FTUE_POPUP } from "@/store/actions/popupAction";
import PopupService from "@/Logic/ClientLogic/Services/PopupService";
import ClientAppManager from "@/Logic/ClientLogic/Services/ClientAppManager";

const Root = styled(RowDiv)`
  width: 100%;
  min-height: 60px;
  height: ${Constant.NAVBAR_HEIGHT};
  top: 0 !important;
  position: sticky;

  @media screen and (max-width: 500px) {
    display: ${(p) => (p.showInMobile ? "none" : "inherit")};
  }
`;

const CTA = styled(YellowButton)`
  padding: 10px 28px;
  border-radius: 8px;
  margin-right: 6px;
`;

const PaidLeadsTopBar = ({
  data,
  bannerText = "",
  ctaText = "Start Free Trial",
}) => {
  const { auth } = useAuthActions();
  const router = useRouter();

  const hasProfileMenu =
    data.profileMenu && Object.keys(data.profileMenu).length > 0;

  return (
    <Root
      justifyContent="space-between"
      alignItems="center"
      showInMobile={!router.asPath.includes("/job-listing")}
    >
      <OutscalAnimatedLogo />
      {bannerText && <p>{bannerText}</p>}
      <CTA
        data-analytics={[UIElements.BUTTON, "paid-leads-cta"]}
        onClick={() => {
          ClientAppManager.instance.analyticsService.captureUserEvent({
            ShownInterest: "ADS-HEADER",
          });
          PopupService.instance.showPopup(FREE_TRIAL_COURSE_FTUE_POPUP);
        }}
      >
        {ctaText}
      </CTA>
    </Root>
  );
};

export default PaidLeadsTopBar;
