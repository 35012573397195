import { gql } from "@apollo/client";

const CREATE_COURSE_LEAD = gql`
  mutation CreateCourseLead(
    $email: String
    $phone_number: PhoneNumberInput
    $utms: UtmsInput!
    $anonymousUserId: String
    $userType: String
    $leadSource: Int!
    $userFormData: JSON
    $userJobProfile: JSON
    $intent: IntentInput
    $timeZone: String
  ) {
    createCourseLead(
      email: $email
      phone_number: $phone_number
      utms: $utms
      anonymousUserId: $anonymousUserId
      userType: $userType
      leadSource: $leadSource
      userFormData: $userFormData
      userJobProfile: $userJobProfile
      intent: $intent
      timeZone: $timeZone
    ) {
      user {
        email
        _id
      }
      userSubscription {
        _id
        user
        currentStatus {
          status
        }
      }
      courseLead {
        _id
        userType
        email
        phone_number
        userJobProfile
      }
      token
      error {
        error
        errorKey
      }
    }
  }
`;

const UPDATE_COURSE_LEAD_AUTH = gql`
  mutation UpdateCourseLeadAuth(
    $courseLeadEmail: String!
    $authEmail: String!
  ) {
    updateCourseLeadAuth(
      courseLeadEmail: $courseLeadEmail
      authEmail: $authEmail
    ) {
      user {
        email
        _id
      }
    }
  }
`;

export { CREATE_COURSE_LEAD, UPDATE_COURSE_LEAD_AUTH };
