import dynamic from "next/dynamic";
import React, { useEffect, useRef, useState } from "react";
import useUserActions from "@/store/actions/UserProfileActions/userAction";
import TopNavBar from "./Bar/TopNavBar";
import NavDrawer from "./Drawer/NavDrawer";
import { useRouter } from "next/router";
import useAuthActions from "@/store/actions/authAction";
import NetworkManager from "@/outscal-commons-frontend/Managers/NetworkManager";
import useLoadingActions from "@/store/actions/loadingAction";
import GameBuildNavBar from "./Bar/GameBuildNavBar";
import { profileOnlyHeaderItems, genericHeaderItems } from "./Bar/headerItems";
import CourseTypeEnum from "@/outscal-commons-frontend/Enums/CourseTypeEnum";
const Loading = dynamic(() => import("@/Components/Loading"));
const StaticJobTopBar = dynamic(() => import("./Bar/StaticJobTopBar"));
const AdsNavBar = dynamic(() => import("./Bar/AdsNavBar"));
import EmptyNavBar from "./Bar/EmptyNavBar";
import { Root } from "./Bar/style";
import { UserCourseStatusEnum } from "@/outscal-commons-frontend/Enums";
import { showScholarshipPlug } from "@/Services/scholarship";
import useMobileBreakPoint from "@/hooks/useMobileBreakPoint";
import ScholarshipReminderPlugController from "@/mvcComponents/Commons/ScholarshipReminderPlug/ScholarshipReminderPlug.controller";
import useScholarshipActions, {
  plugState,
} from "@/store/actions/scholarshipAction";
import { isContentCoursePage } from "@/Logic/ServerLogic/PageDataControllers/CourseDetails/CourseDetailsPageData.model";
import ApolloClientManager from "@/Logic/GraphQL/ApolloClientManager";
import { UPDATE_COURSE_LEAD_AUTH } from "@/Logic/GraphQL/Mutations/courseLeads.mutation";
import ClientAppManager from "@/Logic/ClientLogic/Services/ClientAppManager";
const NoExitTopBar = dynamic(() => import("./Bar/NoExitTopBar"));
import { MdAttachMoney } from "react-icons/md";
import HeaderService from "./Header.service";
import UTMManager from "@/Logic/ClientLogic/Services/UTMManager";
import PaidLeadsTopBar from "./Bar/PaidLeadsTopBar";

function Header() {
  const { auth } = useAuthActions();
  const isMobile = useMobileBreakPoint();
  const {
    user,
    getAndSetUserProfile,
    clearDetails,
    getAndSetLogoutUserProfile,
  } = useUserActions();
  const { setLoadingState, loading } = useLoadingActions();
  const router = useRouter();
  const headerService = new HeaderService(router);
  const { clickFrom } = router.query;
  const [navDrawerOpen, setNavDrawerOpen] = useState(false);
  const [headerItems, setHeaderItems] = useState(genericHeaderItems);
  const [showHeader, setShowHeader] = useState(true);
  const NavBar = useRef(NoExitTopBar);
  const Drawer = useRef(NavDrawer);
  const { scholarshipPlugInfo } = useScholarshipActions();
  const [showScholarshipReminderPlug, setShowScholarshipReminderPlug] =
    useState(false);
  const [navbarId, setNavbarId] = useState(1);

  if (ClientAppManager?.instance?.inIFrame) {
    return null;
  }

  const isGamesRoute =
    router.asPath.includes("/game/") || router.asPath.includes("/games/");

  const checkAndUpdateCourseLeadAuth = async () => {
    try {
      let courseLeadEmail = sessionStorage.getItem("course-lead-email");

      if (courseLeadEmail) {
        let userData = JSON.parse(localStorage.getItem("userData") || "{}");
        await ApolloClientManager.client.mutate({
          mutation: UPDATE_COURSE_LEAD_AUTH,
          variables: {
            courseLeadEmail,
            authEmail: userData.email,
          },
        });
        sessionStorage.removeItem("course-lead-email");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addLinksToUserPRofileMenu = async () => {
    if (user?.userDetails?.username) {
      genericHeaderItems.profileMenu.portfolio.link =
        "/" + user.userDetails.username + "/profile";

      genericHeaderItems.profileMenu.games.link =
        "/" + user.userDetails.username + "/games";

      profileOnlyHeaderItems.profileMenu.portfolio.link =
        "/" + user.userDetails.username + "/profile";

      profileOnlyHeaderItems.profileMenu.games.link =
        "/" + user.userDetails.username + "/games";
    }
    if (user?.userDetails) {
      const paidCourseStatus = user?.userCourseStatus?.filter(
        (courseStatus) =>
          courseStatus?.course?.course_type ===
            CourseTypeEnum.getValue("paid") &&
          courseStatus?.current_status?.status ===
            UserCourseStatusEnum.getValue("started")
      );

      if (user?.userSubscriptions?.length > 0) {
        genericHeaderItems.profileMenu.manageSubscriptions = {
          id: "manageSubscriptions",
          icon: MdAttachMoney,
          label: "Manage Subscriptions",
          link: "/profile/edit?editProfileTab=user-subscriptions",
        };
      }
      if (paidCourseStatus?.length > 0) {
        genericHeaderItems.profileMenu.reviews.link = "/reviews?reviewStatus=0";
      }

      headerService.moveItemToPosition(
        genericHeaderItems.profileMenu,
        "logout"
      );
    }

    const scholarshipPlugVisibility = showScholarshipPlug(user);

    if (scholarshipPlugVisibility) {
      if (isMobile) {
        delete genericHeaderItems.navList.companies;
      }
    } else {
      delete genericHeaderItems.navList.scholarships;
    }

    setHeaderItems({ ...genericHeaderItems });
  };
  useEffect(() => {
    addLinksToUserPRofileMenu();
  }, [user, auth]);

  const setAndShowHeader = (topbar) => {
    setShowHeader(true);
    NavBar.current = topbar;
  };

  const isPaidLead = () => {
    return (
      UTMManager?.instance?.sessionUtm?.utm_medium === "paid" &&
      (router.pathname === "/courses" ||
        router.pathname.includes("/learning-paths") ||
        router.asPath.includes("/game-development-courses") ||
        router.pathname === "/course/[courseSlug]")
    );
  };

  useEffect(() => {
    if (
      isContentCoursePage(router.asPath) ||
      router.asPath.includes("/course-listing") ||
      router.asPath.includes("/community/subscriptions") ||
      router.asPath.includes("/community/india-subscription") ||
      router.asPath.includes("/community/international-subscription")
    ) {
      setShowHeader(false);
    } else if (router.asPath.includes("/job-listing")) {
      setAndShowHeader(StaticJobTopBar);
    } else if (!auth?.token && isPaidLead()) {
      setAndShowHeader(PaidLeadsTopBar);
    } else if (router.asPath.includes("/welcome/")) {
      setAndShowHeader(StaticJobTopBar);
    } else if (router.asPath.includes("/form/")) {
      setAndShowHeader(NoExitTopBar);
    } else if (router.asPath.includes("/social/")) {
      setAndShowHeader(NoExitTopBar);
    } else if (router.asPath.includes("/waitlist-confirmation/")) {
      setAndShowHeader(NoExitTopBar);
    } else if (router.asPath.includes("/course-book-call/")) {
      setAndShowHeader(NoExitTopBar);
    } else if (router.asPath.includes("/job-apply-form/")) {
      setAndShowHeader(StaticJobTopBar);
    } else if (
      router.asPath.includes("/course/") &&
      router.asPath.split("/").length > 4
    ) {
      setShowHeader(false);
    } else if (isGamesRoute) {
      setAndShowHeader(GameBuildNavBar);
    } else if (
      router.asPath.endsWith("/profile") &&
      !router.asPath.includes("/profile/edit")
    ) {
      setAndShowHeader(EmptyNavBar);
    } else if (
      router.asPath.endsWith("-ads") ||
      router.query?.communitySlug?.endsWith("-ads") ||
      router.asPath.endsWith("_ads") ||
      router.query?.courseSlug?.endsWith("_ads")
    ) {
      setAndShowHeader(AdsNavBar);
    } else {
      Drawer.current = NavDrawer;
      setAndShowHeader(TopNavBar);
    }

    if (NavBar.current == StaticJobTopBar) {
      setNavbarId(2);
    } else if (NavBar.current == NoExitTopBar) {
      setNavbarId(3);
    } else if (NavBar.current == AdsNavBar) {
      setNavbarId(4);
    } else {
      setNavbarId(1);
    }
  }, [router.pathname, router.asPath, auth?.token]);

  useEffect(() => {
    if (auth?.token) {
      sessionStorage.removeItem("logout-user-profile");
      localStorage.removeItem("duplicate-email");
      checkAndUpdateCourseLeadAuth();
      getAndSetUserProfile();
    } else {
      clearDetails();
      getAndSetLogoutUserProfile();
    }
  }, [auth]);

  useEffect(() => {
    NetworkManager.setLoading = setLoadingState;
  }, []);

  useEffect(() => {
    if (user?.userDetails && router.isReady) {
      const isWaitlistedUser = user?.userCourseStatus?.find(
        (status) =>
          status?.current_status?.status ==
          UserCourseStatusEnum.getValue("waitlisted")
      );

      if (isWaitlistedUser) {
        return;
      }
      headerService.triggerFtue("ftue1", user, clickFrom);
    }
    if (user?.userDetails && router.isReady) {
      headerService.triggerFtue("ftueCompilers", user, clickFrom);
    }
  }, [router.asPath, user, router.isReady]);

  useEffect(() => {
    const isScholarshipReminderPlugVisible =
      scholarshipPlugInfo?.plugState === plugState.reminder &&
      !router.pathname.includes("scholarship") &&
      !router.pathname.includes("welcome");

    setShowScholarshipReminderPlug(isScholarshipReminderPlugVisible);
  }, [scholarshipPlugInfo, router.pathname]);

  return (
    <div style={{ position: "sticky", top: "0", zIndex: 600 }}>
      <Root
        showHeader={showHeader}
        isCoursesPage={router.pathname === "/courses"}
        key={navbarId}
      >
        {showScholarshipReminderPlug && <ScholarshipReminderPlugController />}
        {loading && <Loading />}
        <NavBar.current
          data={
            isGamesRoute ||
            (router.asPath.endsWith("/profile") &&
              !router.asPath.includes("/profile/edit"))
              ? profileOnlyHeaderItems
              : headerItems
          }
          navDrawerOpen={navDrawerOpen}
          setNavDrawerOpen={setNavDrawerOpen}
          key={navbarId}
          showAvatar={isPaidLead() ? true : false}
          bannerText={
            isPaidLead() ? "Get Access to 20+ Game Programming Courses" : ""
          }
        />
        <Drawer.current
          data={
            isGamesRoute ||
            (router.asPath.includes("/profile") &&
              !router.asPath.includes("/profile/edit"))
              ? profileOnlyHeaderItems
              : headerItems
          }
          navDrawerOpen={navDrawerOpen}
          setNavDrawerOpen={setNavDrawerOpen}
        />
      </Root>
      {/* {!isMobile && showHeader && <RevenueStrip />} */}
    </div>
  );
}

export default Header;
