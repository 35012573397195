export const givenDatesDaysDifference = (date1, date2) => {
  date1 = new Date(date1);
  date2 = new Date(date2);
  let differenceInMs = Math.abs(date1 - date2);
  let differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));

  return differenceInDays;
};

export const addDaysToDate = (
  days,
  options = {}
) => {
  const today = new Date();
  today.setDate(today.getDate() + days);
  return today.toLocaleDateString("en-US", options || {});
};
