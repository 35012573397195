import {
  checkIfFtue1IsFilled,
  checkIfFtueValueCaptureIsFilled,
} from "@/utils/FTUEService";
import HeaderModel from "./Header.model";
import { FtueType } from "./Header.types";
import { NextRouter } from "next/router";

export default class HeaderService {
  private model: HeaderModel;
  private router: NextRouter;

  constructor(router: NextRouter) {
    this.model = new HeaderModel();
    this.router = router;
  }

  private isUrlBlacklistedForFtue(ftueType: FtueType): boolean {
    return this.model[ftueType].blacklistedPaths.some(
      this.matchUrlToPathnameOrAsPath
    );
  }

  private isUrlWhitelistedForFtue(ftueType: FtueType): boolean {
    return this.model[ftueType].whitelistedPaths.some(
      this.matchUrlToPathnameOrAsPath
    );
  }

  private matchUrlToPathnameOrAsPath = (path) =>
    this.router.pathname.includes(path) || this.router.asPath.includes(path);

  triggerFtue(ftueType: FtueType, user: any, clickFrom?: string) {
    if (
      this.isUrlWhitelistedForFtue(ftueType) &&
      !this.isUrlBlacklistedForFtue(ftueType)
    ) {
      this.getFtueFn(ftueType, user, clickFrom)();
    }
  }

  private getFtueFn = (ftueType: FtueType, user: any, clickFrom?: string) => {
    const ftueMap = {
      ftue1: () => {
        const isFtue1Filled = checkIfFtue1IsFilled(user);
        const { asPath, query } = this.router;

        if (
          clickFrom?.includes("scholarship") ||
          (asPath.includes("/profile") && asPath.split("/").length > 4) ||
          query.valueCapture === "codeRun" ||
          isFtue1Filled ||
          asPath.includes("/welcome/ftue1")
        ) {
          return;
        }

        localStorage.setItem("prev-url", asPath);
        this.router.push("/welcome/ftue1");
      },

      ftueCompilers: () => {
        const isFtueValueFilled = checkIfFtueValueCaptureIsFilled(user);
        const { asPath, query } = this.router;

        if (
          query.valueCapture !== "codeRun" ||
          isFtueValueFilled ||
          asPath.includes("/welcome/ftueCompilers")
        ) {
          return;
        }

        localStorage.setItem("prev-url", asPath);
        this.router.push("/welcome/ftueCompilers");
      },
    };

    return ftueMap[ftueType];
  };

  /**
   * Moves a specific key within an object to a desired position.
   * @param menuItems - The object containing menu items.
   * @param key - The key of the item to be moved.
   * @param position - The position to insert at (default: last).
   */
  moveItemToPosition(
    menuItems: Record<string, any>,
    key: string,
    position?: number
  ) {
    if (!menuItems || typeof menuItems !== "object") {
      console.error("Provided menuItems is not an object");
      return;
    }

    if (!menuItems[key]) {
      console.error(`Key "${key}" not found in menuItems`);
      return;
    }

    const item = menuItems[key];
    delete menuItems[key];

    const entries = Object.entries(menuItems);

    entries.splice(position ?? entries.length, 0, [key, item]);

    Object.keys(menuItems).forEach((k) => delete menuItems[k]); // Clear object
    Object.assign(menuItems, Object.fromEntries(entries)); // Refill object
  }
}
