import { FtuePathMatcherType } from "./Header.types";

export default class HeaderModel {
  public ftue1: FtuePathMatcherType;
  public ftueCompilers: FtuePathMatcherType;

  constructor() {
    this.ftue1 = {
      whitelistedPaths: [
        "/jobs",
        "/company/[companySlug]",
        "/jobs-in",
        "-jobs",
        "/companies",
        "/practice",
        "/online-compilers",
        "/mcqs",
      ],
      blacklistedPaths: [
        "/edit?editProfileTab=refer",
        "/invite",
        "welcome",
        "/job-listing",
        "scholarship",
        "course-access",
        "/course",
        "/community/",
        "/auth/google",
        "/auth/discord",
        "/blog/",
        "/form/",
        "/social/",
        "/waitlist-confirmation",
        "/course-book-call/",
      ],
    };

    this.ftueCompilers = {
      whitelistedPaths: ["/online-compilers/"],
      blacklistedPaths: [],
    };
  }
}
