import React from "react";
import dynamic from "next/dynamic";
import Link from "next/link";
import { RowDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";
const OutscalAnimatedLogo = dynamic(() =>
  import("@/Components/OutscalAnimatedLogo")
);
import Avatar from "./Avatar";
import Constant from "@/staticData/constant";
import styled from "styled-components";
import useUserActions from "@/store/actions/UserProfileActions/userAction";

const Root = styled(RowDiv)`
  width: 100%;
  min-height: 60px;
  height: ${Constant.NAVBAR_HEIGHT};
`;

function EmptyNavBar({ data, setNavDrawerOpen }) {
  const { user } = useUserActions();

  const hasProfileMenu =
    data.profileMenu && Object.keys(data.profileMenu).length > 0;
  return (
    <Root justifyContent="space-between" alignItems="center">
      <RowDiv alignItems="center" gap="30px">
        <Link href="/">
          <OutscalAnimatedLogo />
        </Link>
      </RowDiv>
      <RowDiv gap="20px" alignItems="center">
        {hasProfileMenu && user?.userDetails && (
          <Avatar menu={data.profileMenu} />
        )}
      </RowDiv>
    </Root>
  );
}

export default EmptyNavBar;
